
window.___gcfg = {
    lang: "en-GB",
    parsetags: "onload"
};

new WOW({ offset: 70 }).init();

$(document).ready(function () {



    if ($("#HomepageMarker").length === 0) {
        $(".logo-top__logo, .toplinks__link").css("display", "block");
    }
 

    $("img.has-tooltip").tooltip({
        title: function () {
            return $(this).attr("alt");
        },
        placement: "auto"
    });

    $("a.has-tooltip").tooltip({
        placement: "auto"
    });

	function fixHeights() {
		$('.testimonials__link').sameHeight();
		$('.testimonials__carousel-inner').sameHeight();
	}

	setTimeout(fixHeights,300);

    $(window).on("resize", function () {
		setTimeout(fixHeights, 300);
	});

	$(".header__button").each(function (i, el) {
		$(el).attr("data-text", $(el).text());
	});

});

